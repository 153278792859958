import React from 'react';
import BasicInfo from './components/BasicInfo';
import Education from './components/Education';
import SocialLinks from './components/SocialLinks';
import Projects from './components/Projects';


function App() {
  return (
    <div className="mx-auto min-h-screen max-w-screen-xl px-6 md:px-12 lg:px-20 text-slate-900 leading-relaxed">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

        {/* Left-hand-side of the page */}
        <header className="pl-1 pr-1 lg:pr-5 lg:sticky lg:top-0 lg:flex lg:flex-col lg:justify-between lg:h-screen pt-8 pb-12 md:pt-20 md:pb-0 lg:py-20">
          
          <div className="flex flex-col">
            <BasicInfo />
            <Education />
          </div>
          
          <div className="mt-7">
            <SocialLinks />
          </div>
    
          <div className="mb-10">
            <p className="mb-5">&copy; 2024 <a href="https://andres.pages.dev">AG</a> Powered by <a href="https://www.cloudflare.com">Cloudflare</a></p>
          </div>

        </header>

        {/* Right-hand-side of the page */}
        <main className="py-0 md:py-10 lg:py-20">

          <Projects />
        </main>
      </div>
    </div>
  );
}

export default App;
